import React, { useMemo } from 'react';

import { SeoBlock } from '@sravni/react-seo';
import type { Seo } from '@sravni/types/lib/seo';
import SeoBlockTypes from '@sravni/vitrina/lib/enums/SeoBlockTypes';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import { Advertising } from '@src/components/Advertising';
import { Pixel } from '@src/components/Pixel';
import { usePageContext } from '@src/config';
import { FILTERS_NAMES } from '@src/constants/filters';
import { MFO_TOP_OFFERS_HEADERS } from '@src/constants/seo';
import { getAmountRangeToString, getAmountString, getRateRangeString, humanizeTermRange } from '@src/helpers/common';
import { getFullUnitCount } from '@src/helpers/duration';
import { useListPage } from '@src/hooks/useListPage';

import ReviewsBlock from '../ReviewsBlock/ReviewsBlock';
import { SeoBestProducts } from '../SeoBestProducts/SeoBestProducts';
import styles from '../SeoComponents.module.scss';

interface IProps {
  className?: string;
  contentBlocks: Seo;
  items?: IClientMicrocreditListItem[];
}

const MAX_AMOUNT_OFFERS = 10;
const MIN_REQUIRED_AMOUNT_OFFERS = 5;

const buildOverpayment = (amount: number, term: number) => (rate: number) => amount * term * (rate / 100);

function getTopOffersRows(filters: Record<string, any> | undefined, items: IClientMicrocreditListItem[] | undefined) {
  if (!filters) {
    return [];
  }

  const { amount: amountBase, term: termBase } = filters || {};

  const amount = Number(amountBase);
  const term = Number(termBase);
  const getOverpayment = buildOverpayment(amount, term);

  const chosenItems = items
    ?.filter(
      (item) =>
        item.amountRange.from <= amount &&
        item.amountRange.to >= amount &&
        getFullUnitCount(item.termRange.from, item.termRange.fromUnit) <= term &&
        getFullUnitCount(item.termRange.to, item.termRange.toUnit) >= term,
    )
    .sort((leftItem, rightItem) => getOverpayment(leftItem.rateRange.to) - getOverpayment(rightItem.rateRange.to))
    .slice(0, MAX_AMOUNT_OFFERS);

  if (!chosenItems || chosenItems.length < MIN_REQUIRED_AMOUNT_OFFERS) {
    return [];
  }

  return chosenItems.map((item, index) => [
    <div key={item._id} className={styles.pixel}>
      {item.advertising?.monetization?.pixels?.display ? (
        <Pixel
          source={`search|position${index}`}
          link={item.advertising?.monetization?.pixels?.display}
          affSub4={{
            organizationName: item.organization.name,
            productName: item.name,
            organizationId: item.organization._id,
            productId: item._id,
          }}
        />
      ) : null}
      <Advertising analytics={{ ...item, eventAction: 'Платный клик|SEO-таблица' }} source="search" position={index}>
        {item.organization.name}
      </Advertising>
    </div>,
    getAmountRangeToString({ amountRange: item.amountRange }),
    getRateRangeString(item.rateRange, false, false),
    humanizeTermRange(item.termRange, { initialForm: false, termWithUnit: false }),
    getAmountString(getOverpayment(item.rateRange.from)),
    getAmountString(getOverpayment(item.rateRange.to)),
  ]);
}

function extendContentBlocks(seo: Seo, items: IClientMicrocreditListItem[] | undefined, hasBestProduct: boolean) {
  return seo.contentBlocks
    ?.map((block) => {
      switch (block.type) {
        case SeoBlockTypes.BestProduct: {
          if (!hasBestProduct) {
            return null;
          }

          return {
            type: block.type,
            bestProductComponent: <SeoBestProducts title={block.title} />,
          };
        }
        case SeoBlockTypes.Review: {
          return {
            type: block.type,
            reviewsComponent: <ReviewsBlock />,
          };
        }
        case SeoBlockTypes.TopOffers: {
          return {
            type: block.type,
            headers: [
              Boolean(seo.productName) && seo.productName !== '-' ? seo.productName : MFO_TOP_OFFERS_HEADERS[0],
              ...MFO_TOP_OFFERS_HEADERS.slice(1),
            ],
            rows: getTopOffersRows(block.filters, items),
            // @ts-ignore
            title: block.title.replace('{количество.организаций.топ}', String(block.rows?.length || '')),
          };
        }
        case SeoBlockTypes.ExpertOpinion: {
          return {
            type: block.type,
            title: block.title,
            answersData: block.answersData.map((data) => ({
              ...data,
              link: `/u/${data.expertId}/`,
            })),
          };
        }
        case SeoBlockTypes.FAQ: {
          return {
            type: block.type,
            title: block.serviceResponseData.title,
            items: block.serviceResponseData.items,
          };
        }

        case SeoBlockTypes.Html: {
          return {
            type: block.type,
            text: block.text,
            author: block.author,
          };
        }
        default:
          return null;
      }
    })
    .filter(Boolean);
}

export const SeoBlocks: React.FC<IProps> = React.memo(
  ({ className, contentBlocks, items }): React.ReactElement | null => {
    const { listKey } = usePageContext();
    const { filter } = useListPage(listKey);
    const hasBestProduct = filter.filters[FILTERS_NAMES.OFFERS_TYPE];

    const modifiedContentBlocks = useMemo(
      () => extendContentBlocks(contentBlocks, items, !hasBestProduct),
      [contentBlocks, items, hasBestProduct],
    );
    if (!modifiedContentBlocks) {
      return null;
    }

    return <SeoBlock className={className} contentBlocks={modifiedContentBlocks} />;
  },
);
