import type { FC } from 'react';
import { memo } from 'react';

import { Typography } from '@sravni/react-design-system';

import Card from '@src/components/ProductList/components/Card';
import { NA_KARTU_PAGE_DEFAULT_AMOUNT, NA_KARTU_PAGE_DEFAULT_TERM } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import { useSelectCreditsList } from '@src/reducers/microcredits';

import style from './SeoBestProducts.module.scss';

const { Heading } = Typography;

interface IProps {
  title?: string;
}

export const SeoBestProducts: FC<IProps> = memo(({ title }) => {
  const bestProductList = useSelectCreditsList(ListKeys.BESTPRODUCTS);
  const items = bestProductList?.items || [];

  if (!items.length) {
    return null;
  }

  return (
    <div>
      <Heading className={style.title} level={2}>
        {title || 'Популярные предложения'}
      </Heading>
      <div className={style.cards}>
        {items.map((item, index) => (
          <Card
            key={item._id}
            offer={item}
            position={index}
            query={{ amount: NA_KARTU_PAGE_DEFAULT_AMOUNT, term: NA_KARTU_PAGE_DEFAULT_TERM }}
          />
        ))}
      </div>
    </div>
  );
});
