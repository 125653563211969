type AnchorLinks = {
  label: string;
  link: string;
};

export const ANCHOR_LINKS: AnchorLinks[] = [
  {
    label: 'Займ онлайн',
    link: '/zaimy/onlain/',
  },
  {
    label: 'Займ без отказа',
    link: '/zaimy/na-kartu-bez-otkazov/',
  },
  {
    label: 'Займ с плохой кредитной историей',
    link: '/zaimy/s-plokhoj-kreditnoj-istoriej/',
  },
  {
    label: 'Займ без процентов',
    link: '/zaimy/bez-protsentov/',
  },
  {
    label: 'Займ на долг',
    link: '/zaimy/top/',
  },
  {
    label: 'Займ под залог ПТС',
    link: '/zaimy/pod-pts/',
  },
  {
    label: 'Займ через госуслуги',
    link: '/zaimy/cherez-gosuslugi/',
  },
  {
    label: 'Займ до зарплаты',
    link: '/zaimy/na-kartu-do-zarplaty/',
  },
  {
    label: 'Долгосрочный займ',
    link: '/zaimy/dolgosrochnye/',
  },
  {
    label: 'Займ с просрочками',
    link: '/zaimy/s-prosrochkami/',
  },
];
