import cn from 'classnames';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { Layout } from '@sravni/react-design-system';
import { PreFooter } from '@sravni/react-pre-footer';
import { useIsMobile } from '@sravni/react-utils';
import type { Seo } from '@sravni/types/lib/seo';

import { Breadcrumbs } from '@src/components/Breadcrumbs';
import { AnchorLinkList } from '@src/components/PageHeader/AnchorLinkList';
import { usePageContext } from '@src/config';
import { getCreditsLists } from '@src/reducers/microcredits';
import { useHasUtmLabel } from '@src/reducers/route';
import { isKZLandingPage, isMainPage, isNaKartuPage, isSpecialPage } from '@src/utils/routing';

import { SeoBlocks } from './SeoBlocks/SeoBlocks';
import styles from './SeoComponents.module.scss';
import { SeoDisclaimer, SeoDisclaimerBranding } from './SeoDisclaimer';

interface IProps {
  className?: string;
  seo: Seo;
  breadcrumbs?: Seo['breadcrumbs'];
  breadcrumbsClassName?: string;
  prefooter?: Seo['prefooter'];

  showAnchorLinks?: boolean;
}

export const SeoComponents: FC<IProps> = memo(
  ({ className, seo, breadcrumbs = [], breadcrumbsClassName, prefooter = [], showAnchorLinks }) => {
    const { asPath, query } = useRouter();
    const isMobile = useIsMobile();
    const isRenderDisclaimer = isMainPage(asPath, query) || isSpecialPage(asPath, query);

    const { listKey } = usePageContext();
    const { items } = useSelector(getCreditsLists)[listKey] || { items: [] };
    const hasUtmLabel = useHasUtmLabel();

    if (!isRenderDisclaimer && hasUtmLabel) {
      return null;
    }

    const [pathWithoutQuery] = asPath.split('?');
    const hasSubHeadingAfterOffers =
      seo.subHeading &&
      isMobile &&
      !isNaKartuPage(pathWithoutQuery) &&
      !isKZLandingPage(pathWithoutQuery) &&
      !hasUtmLabel;

    return (
      <div className={cn(styles.container, className)}>
        {hasSubHeadingAfterOffers && <div className={styles.subHeading}>{seo.subHeading}</div>}

        {showAnchorLinks && (
          <div className={styles.linksContainer}>
            <AnchorLinkList />
          </div>
        )}

        {!hasUtmLabel && (
          <Layout className={styles.layout}>
            <SeoBlocks className={styles.table} contentBlocks={seo} items={items} />
          </Layout>
        )}

        {isRenderDisclaimer && (
          <Layout className={cn({ [styles.utm]: hasUtmLabel })}>
            <SeoDisclaimer />
            <SeoDisclaimerBranding />
          </Layout>
        )}

        {!hasUtmLabel && <Breadcrumbs items={breadcrumbs} className={breadcrumbsClassName} />}

        {!hasUtmLabel && <PreFooter columns={prefooter} className={styles.prefooter} />}
      </div>
    );
  },
);
