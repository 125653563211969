import { useRouter } from 'next/router';

import { AnchorLinkItem } from '@src/components/PageHeader/AnchorLinkList/components/AnchorLinkItem';
import { ANCHOR_LINKS } from '@src/components/PageHeader/AnchorLinkList/consts';
import { useHasUtmLabel } from '@src/reducers/route';
import { isKZPage } from '@src/utils/routing';

import styles from './style.module.scss';

export const AnchorLinkList = () => {
  const { asPath } = useRouter();
  const [pathWithoutQuery] = asPath.split('?');
  const hasUtmLabel = useHasUtmLabel();

  if (pathWithoutQuery === '/zaimy/' || hasUtmLabel || isKZPage(asPath)) return null;

  return (
    <div className={styles.container}>
      {ANCHOR_LINKS.map(({ label, link }) => (
        <AnchorLinkItem key={link} link={link} label={label} active={pathWithoutQuery === link} />
      ))}
    </div>
  );
};
