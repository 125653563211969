import cn from 'classnames';
import React from 'react';

import type { IItemsProps } from '..';
import { Item } from '../Item';

import styles from './styles.module.scss';

export const Breadcrumbs: React.FC<IItemsProps> = ({
  className,
  items,
  homeTitle = 'Сравни.ру',
  isHomeVisible = true,
}) => (
  <nav className={cn(styles.nav, className)}>
    {isHomeVisible && (
      <Item href="/" title={homeTitle} id="breadcrumbs-item-0">
        {homeTitle}
      </Item>
    )}
    {items.map((item) => {
      const id = item.url;
      return (
        <Item id={id} key={id} href={item.url} title={item.title}>
          {item.title}
        </Item>
      );
    })}
  </nav>
);
