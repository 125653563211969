export const REVIEWS_PAGE_SIZE = 10;
export const REVIEWS_MIN_COUNT = 2;

export const DEFAULT_PARAMS = {
  ReviewObjectType: 'mfi',
  Tag: 'microcredits',
  OrderBy: 'highRateFirst',
  Rated: 'good',
  pageSize: REVIEWS_PAGE_SIZE,
  UniqueOnly: true,
};
