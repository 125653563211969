import React from 'react';

import { Reviews } from '@sravni/react-seo';

import { REVIEWS_MIN_COUNT } from '@src/constants/reviews';
import { useReviewsState } from '@src/reducers/reviews';

import styles from './ReviewsBlock.module.scss';

const ReviewsBlock: React.FC = React.memo(() => {
  const reviews = useReviewsState();

  if (!reviews) {
    return null;
  }

  return reviews?.length > REVIEWS_MIN_COUNT ? (
    <Reviews
      className={styles.wrapper}
      reviewItemClassName={styles.reviewItem}
      heading="Отзывы о продукте"
      productType="zaimy/mfo"
      allReviewsLink="/zaimy/otzyvy/"
      reviews={reviews}
      gradient
    />
  ) : null;
});

export default ReviewsBlock;
